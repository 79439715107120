.loginSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  > title {
    display: block;
    font-size: 42px;
    font-weight: 600;
  }

  > button {
    width: 120px;
    height: 62px;
    margin: 15px;
  }
}

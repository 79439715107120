/* stylelint-disable scale-unlimited/declaration-strict-value */
.base {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: inherit;
  height: 60px;
  padding: 0 15px;
  // background-color: aqua;

  title {
    display: block;
    font-size: 32px;
    font-weight: 700;
    cursor: pointer;

    > a {
      color: black;
      text-decoration: none;
    }
  }

  aside {
    display: flex;
    justify-content: space-between;
    width: 240px;

    nav {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 100px;

      > .item {
        font-size: 24px;
        font-weight: 500;
        color: #525252;
        text-decoration: none;
      }
    }

    .profile {
      width: 32px;
      height: 32px;
      margin-left: 30px;
      cursor: pointer;
      background-color: orange;
      border-radius: 16px;
    }

    button {
      position: relative;
      width: 32px;
      height: 32px;
      cursor: pointer;
      border: none;
      border-radius: 50%;
    }
  }
}

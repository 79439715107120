.backBoard {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  background-color: rgba($color: #000000, $alpha: 60%);

  .modal {
    position: absolute;
    top: calc(50% - 80px);
    left: calc(50% - 180px);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 360px;
    height: 160px;
    background-color: #ffffff;
    border-radius: 8px;

    > header {
      width: 100%;
      height: 48px;

      > title {
        display: block;
        font-weight: 600;
        line-height: 48px;
        text-align: center;
      }
    }

    > main {
      height: 62px;

      > form {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 40px;
        padding: 0 20px;

        > input {
          text-align: center;
        }

        .datePicker {
          box-sizing: border-box;
          width: inherit;
          padding: 0 20px;
          text-align: center;
        }
      }
    }
  }
}

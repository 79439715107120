.titleSection {
  display: flex;
  align-items: center;
  justify-content: center;
  width: inherit;
  height: 100px;

  title {
    display: block;
    font-size: 58px;
  }
}

.postInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 2px;
  border-bottom: 2px solid black;

  >p {
    margin: 0;
  }

  .btnWrapper {
    > a {
      padding: 0;
      margin-left: 10px;
      font-size: 14px;
      color: #000000;
      text-decoration: none;
      cursor: pointer;
      border: none;
    }

    >button {
      height: 24px;
      padding: 0;
      margin-left: 10px;
      cursor: pointer;
      background-color: #ffffff;
      border: none;
    }
  }
}

.itemListSection {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: scroll;

  table {
    overflow: scroll;
    border-collapse: collapse; // Table의 보더 설정!

    thead {
      tr {
        border-bottom: 2px solid black;

        :first-child {
          min-width: 200px;
        }

        th {
          min-width: 85px;
          max-width: 95px;
        }
      }
    }

    tbody {
      tr {
        height: 48px;
        text-align: center;
        cursor: pointer;
        border-bottom: 1px solid #a8a8a8;
      }
    }
  }
}

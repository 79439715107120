section {
  max-width: inherit;
  max-height: 100%;
}

main {
  width: inherit;
  margin: 0 5%;

  .titleSection {
    display: flex;
    align-items: center;
    justify-content: center;
    width: inherit;
    height: 100px;

    title {
      display: block;
      font-size: 58px;
    }
  }

  .postInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 2px;
    border-bottom: 2px solid black;

    > p {
      margin: 0;
    }

    > button {
      height: 24px;
      padding: 0;
      margin: 0;
      cursor: pointer;
      background-color: #ffffff;
      border: none;
    }
  }

  .itemListSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: inherit;

    > table {
      width: 100%;
      border-spacing: 0 15px;
      border-collapse: collapse; // Table의 보더 설정!

      > thead > tr {
        height: 36px;
        border-bottom: 2px solid black;
      }

      > tbody > tr {
        height: 48px;
        cursor: pointer;
        border-bottom: 1px solid #a8a8a8;

        > td {
          text-align: center;
        }

        :first-child {
          width: 60%;
        }

        :nth-child(2) {
          width: 40%;
        }
      }
    }
  }
}

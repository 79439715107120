.backBoard {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  background-color: rgba($color: #000000, $alpha: 60%);

  .modal {
    position: absolute;
    top: calc(50% - 150px);
    left: calc(50% - 180px);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 360px;
    height: 300px;
    background-color: #ffffff;
    border-radius: 8px;

    > header {
      width: 100%;
      height: 48px;

      > title {
        display: block;
        font-weight: 600;
        line-height: 48px;
        text-align: center;
      }
    }

    > main {
      height: auto;

      > form {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        padding: 0 20px;

        > input {
          margin-bottom: 10px;
          text-align: center;
        }

        > textarea {
          margin-bottom: 10px;
        }
      }
    }

    > footer {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}

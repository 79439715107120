.titleSection {
  display: flex;
  align-items: center;
  justify-content: center;
  width: inherit;
  height: 100px;

  title {
    display: block;
    font-size: 58px;
  }
}

.essay {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: inherit;
  border-top: 2px solid black;

  title {
    display: block;
    margin: 5px 0;
    font-size: 26px;
  }

  .subInfo {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 5px 10px;
    border-top: 2px solid black;
    border-bottom: 1px solid black;
  }

  > main {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    padding: 20px;
    line-height: 22px;
    letter-spacing: 1px;
    word-spacing: 2px;

    .downSide {
      position: absolute;
      top: 0;
      right: 0;

      > a {
        margin-left: 10px;
        font-size: 14px;
        color: black;
        text-decoration: none;
      }
    }
  }

  > footer {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 120px;
    padding: 30px;
    border-top: 1px solid black;

    > p {
      position: absolute;
      top: -14px;
      left: 4px;
      font-size: 18px;
      font-weight: 700;
    }
  }
}

/* stylelint-disable scale-unlimited/declaration-strict-value */
.back {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 120px;
  height: 32px;
  border-radius: 20px;

  button {
    width: 60px;
    height: 20px;
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
    color: gray;
    text-align: center;
    cursor: pointer;
    background: none;
    border: none;
    opacity: 0.6;
  }

  .select {
    color: black;
    opacity: 1;
  }
}
